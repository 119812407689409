<template>
  <div id="app">
    <vue-page-stack>
      <router-view :key="$route.fullPath"></router-view>
    </vue-page-stack>
    <!-- <keep-alive> :key="$route.fullPath"
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" /> -->
    <!-- <Navbar v-if="$route.meta.navShow" /> -->
  </div>
</template>

<script>
import Navbar from './components/navbar.vue';
export default {
  components: {
    // Navbar
  },
  created() {
    if (localStorage.getItem("Token")) {
      localStorage.removeItem("Token");
    }
  }
}
</script>

<style lang="sass">
@import "@/styles/base.scss";
</style>
