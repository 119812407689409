import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      navBar: [], // 顶部科室菜单
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  created() {
    this.getNavbar();
  },
  methods: {
    ...mapActions(['setNavbar']),
    // 获取顶部科室菜单
    getNavbar() {
      this.$axios.post('/Api/Api/Web/Subject/GetUserDepartmentList?CustomerID=' + this.userInfo.Id).then(res => {
        this.navBar = res.Data;
        let index = res.Data.findIndex(item => item.Code == "other");
        if (index != -1) {
          this.navBar.splice(index, 1);
        }
        // this.setNavbar(this.navBar);
      })
    },
    // 跳转到对应科室页面
    toDepartment(code, name) {
      let url = `/department?code=${code}`;
      this.BuriedPoint(1, name, code, url);
      this.$router.push({
        path: 'department',
        query: {
          code: code
        }
      })
    },
    /* 模块关键字埋点  
    BPType: 1.页面地址  2.关键字
    Name: 模块名称
    TCode
    Url: 路由地址 */
    BuriedPoint(BPType, Name, TCode, Url) {
      this.$axios.post('/Api/Api/Web/BuriedPoint/AddBP', {
        BPType,
        Name,
        TCode,
        Url
      }).then(res => {
        // console.log(res);
      })
    }
  }
}