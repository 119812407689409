import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import "amfe-flexible";
import storage from "./common/utils/storage";

import VuePageStack from 'vue-page-stack';
Vue.use(VuePageStack, { router });

import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

// const TCPlayer = window.TCPlayer;
// Vue.prototype.TCPlayer = TCPlayer;

const acPlayerToolInit = window.acPlayerToolInit;
Vue.prototype.TCPlayer = acPlayerToolInit;

import Swiper2, {Navigation, Pagination,} from 'swiper'
Swiper2.use([Navigation, Pagination,]);
import VueAwesomeSwiper from "vue-awesome-swiper";
import 'swiper/swiper-bundle.css';
Vue.use(VueAwesomeSwiper);

import waterfall from 'vue-waterfall2'
Vue.use(waterfall)

import * as custom from './common/filters'
Object.keys(custom).forEach(key => { Vue.filter(key, custom[key])});

Vue.config.productionTip = false;

Vue.config.ignoredElements = ['wx-open-launch-weapp'];

Vue.prototype.$axios = axios;

axios.interceptors.request.use(
  config => {
    if (storage.getStore("Token")) {
      config.headers.common["token"] = storage.getStore("Token");
    }
    if(storage.getStore("access_token")) {
      config.headers.common["access_token"] = storage.getStore("access_token");
    }
    return config;
  },
  error => {
    console.log(error);
  }
);

axios.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.RetCode == '10000') {
      return res;
    } else if (res.RetCode == '10001') {
      Vue.prototype.$dialog.alert({
        title: '提示',
        message: '您当前还未登录，请先去登录',
      }).then(() => {
        let fullPath = router.currentRoute.fullPath;
        sessionStorage.setItem("redirectUrl", fullPath.substring(0, fullPath.length - 19));
        store.dispatch('setLoginStatus', false);
        router.push('/entrance');
      });
    } else if (res.RetCode == '10009') {
      axios.post('/Api/Api/Web/Token/GetToken', {
        "key": process.env.VUE_APP_ACCESS_TOKEN_KTY
      }).then(res => {
        storage.setStore('access_token', res.Data);
        let lastPath = storage.getStore('lastPath');
        router.push(lastPath);
      })
    } else {
      // return Promise.reject(new Error(res.RetMsg || 'Error'));
      return res;
    }
  },
  error => {
    return Promise.reject(error);
  }
)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
